


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { getCustomConfig } from './resources' // ChartIQ library resources
import { getComponent } from '@/utils/helpers';
import { getView } from '@/utils/helpers';
// @ts-ignore
import { CIQ } from 'chartiq/js/componentUI' // Required for types in chartInitialized
@Component({
	components: {
		AdvancedChartComponent: () => getView('ChartIQSUB'),
	}
})
export default class AdvancedWrapper extends Vue {
	@Prop({ type: String, default: 'TASQ' }) symbol!: string
	@Prop({ type: String, default: '_advanced-chart' }) chartId!: string
	@Prop({ type: Boolean, default: true }) restore!: boolean
	@Prop({ type: Function, default: ({}) => {} }) onChartReady!: Function
	config: any
	constructor() {
		super()
		const { symbol, chartId, onChartReady, restore } = this
		this.config = getCustomConfig({ symbol: 'Tasq', chartId, onChartReady, restore })
		// this.config2 = getCustomConfig({ symbol: 'Tasq1', chartId: 'container2', onChartReady, restore })
	}
	chartInitialized({
		/* eslint-disable */
		chartEngine,
		uiContext,
		config
		/* esllint-enable */
	}: {
		chartEngine: CIQ.ChartEngine
		uiContext: CIQ.UI.Context
		config: any
	}) {
		// Methods for capturing state changes in chart engine and UI
		// 	Channel subscribe example to listen to breakpoint changes
			const { channels } = config
			const channelSubscribe = CIQ.UI.BaseComponent.prototype.channelSubscribe
			channelSubscribe(
				channels.breakpoint,
				(value: any) => {
					console.log('channels.breakpoint', value)
				},
				chartEngine
			)
		// Layout listener example, see parameters at https://documentation.chartiq.com/global.html#layoutEventListener
		uiContext.stx.addEventListener('layout', ({ layout }: any) => {
			console.log('layout changed', layout)
		})
		// import 'chartiq/examples/feeds/L2_simulator'
		// CIQ.simulateL2({ stx: chartEngine, onInterval: 1000, onTrade: true })
	}
}
